export const TIME_VALIDATE_FN = (value: string, type: string): string => {
    if (type === 'seconds') {
        if (!value.match(/^\d+(\.\d{1,3}){0,1}$/)) {
            return `Accepted value examples i.e 23 (for 23 seconds) \
            or 23.453 for (23 seconds and 453 milliseconds)`;
        }
    }
    if (type === 'formated_time') {
        if (!value.match(/^(\d{1,2}:){0,2}(\d{1,2})(\.\d{1,3}){0,1}$/)) {
            return `Accepted value examples i.e 23 (for 23 seconds) \
            or 23.453 for (23 seconds and 453 milliseconds)
            or 12:23.453 for (12 minutes 23 seconds and 453 milliseconds)
            or 3:1:23.453 for (3 hours 1 minute 23 seconds and 453 milliseconds)`;
        }
    }
    return null;
};

export const OUTPUT_EXT_FROM_OPTIONS_FN = (allOptions: any) => {
    if (allOptions.formCategories) {
        const outputExtensionOptions = allOptions.formCategories
            .find(f => f.category === 'outputExt');
        const outputExtensionOption = outputExtensionOptions && outputExtensionOptions.formGroups[0];
        return outputExtensionOption ? outputExtensionOption.selections.find(a => a.selected).formValue : null;
    }
    return null;
};


export const OUTPUT_VIDEO_ENCODER_FROM_OPTIONS_FN = (allOptions: any, categoryName: string, formGroupName: string) => {
    const category = allOptions.formCategories.find(cat => cat.category === categoryName);

    if (category) {
        const formGroup = category.formGroups.find(group => group.formGroupName === formGroupName);

        if (formGroup) {
            const selectedItem = formGroup.selections.find(selection => selection.selected);

            if (selectedItem) {
                return selectedItem.formValue;
            }

            // Check nested options recursively:
            for (const selection of formGroup.selections) {
                if (selection.formChildren) {
                    for (const child of selection.formChildren) {
                        if (child.formGroup && child.formGroup.selections) {
                            const nestedSelectedItem = child.formGroup.selections.find(nestedSelection => nestedSelection.selected);
                            if (nestedSelectedItem) {
                                return nestedSelectedItem.formValue;
                            }
                        }
                    }
                }
            }
        }
    }

    return null; // Return null if no item is found
}