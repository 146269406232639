import { inject, injectable } from 'inversify';
import { Observable, ReplaySubject } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';

import { BrowserUtils } from '../ui-utils/browser-utils';
import { OUTPUT_EXT_FROM_OPTIONS_FN, OUTPUT_VIDEO_ENCODER_FROM_OPTIONS_FN, TIME_VALIDATE_FN } from '../utils/options.utils';
import { UserSettings } from '../utils/request.utils';
import { SettingsService } from './settings.service';

declare var window;

@injectable()
export class WindowService {

    private windowClicked = true;
    get clicked(): boolean {
        return this.windowClicked;
    }

    private windowMouseMoved = false;
    get mousemoved(): boolean {
        return this.windowMouseMoved;
    }

    private windowMouseDowned = false;
    get mousedowned(): boolean {
        return this.windowMouseDowned;
    }

    private _userEngagedTriggered = false;
    private _userEngaged = new ReplaySubject<void>(1);
    get userEngaged() {
        return this._userEngaged.asObservable();
    }

    private _userClickedTriggered = false;
    private _userClicked = new ReplaySubject<void>(1);
    get userClicked() {
        return this._userClicked.asObservable();
    }

    private _onResize = new ReplaySubject<void>(1);
    get onResize() {
        return this._onResize.asObservable();
    }

    private _onScroll = new ReplaySubject<Event>(1);
    get onScroll() {
        return this._onScroll.asObservable();
    }

    private _onFileSelectBtnClicked = new ReplaySubject<{ dispatchedAt: Date }>(1);
    get onFileSelectBtnClicked() {
        return this._onFileSelectBtnClicked;
    }

    public fileSelectBtnClicked() {
        this._onFileSelectBtnClicked.next({ dispatchedAt: new Date() });
    }

    constructor(
        @inject(SettingsService) private settingsService: SettingsService,
    ) {
        if (BrowserUtils.hasWindow()) {
            window['TIME_VALIDATE_FN'] = TIME_VALIDATE_FN;
            window['OUTPUT_EXT_FROM_OPTIONS_FN'] = OUTPUT_EXT_FROM_OPTIONS_FN;
            window['OUTPUT_VIDEO_ENCODER_FROM_OPTIONS_FN'] = OUTPUT_VIDEO_ENCODER_FROM_OPTIONS_FN;
        }
    }

    public initialize(): Observable<UserSettings> {
        this.registerWindowEvents();
        return this.settingsService.userSettings.pipe(
            timeout(4000)
        ).pipe(tap({
            next: (userSettings) => {
                if (userSettings.resolveMousemoveEventsStartup) {
                    this.updateUserEngagedState();
                }
            },
            error: () => {
                // not important
            },
        }));
    }

    private registerWindowEvents() {
        // if (isPlatformBrowser(this.platformId)) {
        this.setWindowClickListener();
        this.setWindowMouseMoveListener();
        this.setWindowMouseDownListener();
        this.setWindowResizeListener();
        this.setWindowScrollListener();
        // }
    }

    setWindowMouseMoveListener() {
        const mousemoveEventListner = () => {
            this.windowMouseDowned = true;
            window.removeEventListener('mousemove', () => mousemoveEventListner());
            window.removeEventListener('touchmove', () => mousemoveEventListner());
            this.updateUserEngagedState();
        };
        window.addEventListener('mousemove', () => mousemoveEventListner(), { passive: true });
        window.addEventListener('mousemove', () => mousemoveEventListner(), { passive: true });
    }

    setWindowMouseDownListener() {
        const mousedownEventListner = () => {
            this.windowMouseDowned = true;
            window.removeEventListener('mousedown', () => mousedownEventListner());
            window.removeEventListener('touchstart', () => mousedownEventListner());
            this.updateUserEngagedState();
        };
        window.addEventListener('mousedown', () => mousedownEventListner(), { passive: true });
        window.addEventListener('touchstart', () => mousedownEventListner(), { passive: true });
    }

    setWindowClickListener() {
        const clickEventListner = () => {
            this.windowClicked = true;
            window.removeEventListener('click', () => clickEventListner());
            window.removeEventListener('touchend', () => clickEventListner());
            this.updateUserEngagedState();
            this.updateUserClickedState();
        };
        window.addEventListener('click', () => clickEventListner());
        window.addEventListener('touchend', () => clickEventListner());
    }

    setWindowResizeListener() {
        const resizeEventListner = (e) => {
            this._onResize.next(e)
        };
        window.addEventListener('resize', (e) => resizeEventListner(e));
    }

    setWindowScrollListener() {
        const scrollEventListner = (e) => {
            this._onScroll.next(e)
        };
        window.addEventListener('scroll', (e) => scrollEventListner(e));
    }

    runOnWindow(fn: (window) => any): Window {
        try {
            return fn(window || {});
        } catch (e) {
            console.error('Error executing window cmd', e)
        }
        return null;
    }

    private updateUserEngagedState() {
        if (!this._userEngagedTriggered) {
            this._userEngagedTriggered = true;
            this._userEngaged.next();
            this._userEngaged.complete();
        }
    }

    private updateUserClickedState() {
        if (!this._userClickedTriggered) {
            this._userClickedTriggered = true;
            this._userClicked.next();
            this._userClicked.complete();
        }
    }

    manuallyActivateTriggers() {
        this.updateUserEngagedState();
        this.updateUserClickedState();
    }
}
