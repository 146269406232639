import { isObject, isString } from './lodash-min';

import { OUTPUT_EXT_FROM_OPTIONS_FN, OUTPUT_VIDEO_ENCODER_FROM_OPTIONS_FN, TIME_VALIDATE_FN } from '../utils/options.utils';

export class JSONUtils {
    static parse(jsonString: string) {
        const jsonObjWithSerializedFunctions = eval(jsonString);
        JSONUtils.convertStringFnToFnInPlace(jsonObjWithSerializedFunctions);
        return jsonObjWithSerializedFunctions;
    }

    private static convertStringFnToFnInPlace(obj) {
        try {
            if (Array.isArray(obj)) {
                const converted = [];
                for (const item of obj) {
                    converted.push(JSONUtils.convertStringFnToFnInPlace(item));
                }
                obj.splice(0, obj.length, ...converted)
            } else if (isObject(obj)) {
                try {
                    for (const key in obj) {
                        obj[key] = JSONUtils.convertStringFnToFnInPlace(obj[key]);
                    }
                } catch (e) {
                }
            } else if (isString(obj)) {
                try {
                    if (obj.startsWith("xxxxx")) {
                        if (globalThis) {
                            globalThis.OUTPUT_VIDEO_ENCODER_FROM_OPTIONS_FN = OUTPUT_VIDEO_ENCODER_FROM_OPTIONS_FN;
                            globalThis.OUTPUT_EXT_FROM_OPTIONS_FN = OUTPUT_EXT_FROM_OPTIONS_FN;
                            globalThis.TIME_VALIDATE_FN = TIME_VALIDATE_FN;
                        }
                        // TODO just keep it as function instead of xxxx and locally do the conversion
                        return eval(`var ${obj}; xxxxx`);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        } catch (e) {
            console.error(e);
        }
        return obj;
    }

    public static safeStringify(obj: any) {
        const getCircularReplacer = () => {
            const seen = new WeakSet();
            return (key, value) => {
                if (typeof value === "object" && value !== null) {
                    if (seen.has(value)) {
                        return;
                    }
                    seen.add(value);
                }
                return value;
            };
        };

        return JSON.stringify(obj, getCircularReplacer());
    }
}